<template>
    <div
        v-bind="wrapperProps"
    >
        <v-progress-circular
            indeterminate
            color="primary"
            class="preloader"
            :style="iframePreloaderStyles"
        ></v-progress-circular>
        <iframe
            v-show="iframeSrc"
            ref="youtubeIframeView"
            :src="iframeSrc"
            :title="item.title"
            width="100%"
            height="315px"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="youtube-embed"
            v-on:load="iframeOnLoad"
            :style="iframeLoadableStyles"
        ></iframe>
    </div>
</template>

<script>
import getVideoId from 'get-video-id'
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'

export default {
    name: 'YoutubeView',
    mixins: [ mediaViewMixin ],
    computed: {
        iframeSrc() {
            if(!this.item) {
                return null
            }

            const { id } = getVideoId(this.item.src)
            return `https://www.youtube.com/embed/${ id }`
        },
        wrapperProps() {
            const classes = [ 'media-youtube-view' ]

            if(this.fullscreen) {
                classes.push('media-youtube-view--' + 'fullscreen')

                if(this.winWidth > this.winHeight) {
                    classes.push('media-youtube-view--' + 'album')
                } else {
                    classes.push('media-youtube-view--' + 'portrait')
                }
            }

            return {
                class: classes
            }
        }
    },
    mounted() {
        this.winResize()
    },
    methods: {
        winResize() {
            const youtubeIframeView = this.$refs.youtubeIframeView

            if(!youtubeIframeView) {
                return
            }

            // const winWidth = this.winWidth
            // const winHeight = this.winHeight
            const clientWidth = youtubeIframeView.clientWidth
            // const clientHeight = youtubeIframeView.clientHeight
            const clientAspectRatio = 560 / 315

            if(clientWidth / clientAspectRatio < this.winHeight) {
                youtubeIframeView.height = clientWidth / clientAspectRatio + 'px'
            } else {
                youtubeIframeView.height = this.winHeight - 100 + 'px'
                youtubeIframeView.width = '100%'
            }
        }
    }
}
</script>

<style lang=scss scoped>
.media-youtube-view {
    width: 100%;
    position: relative;

    .preloader {
        position: absolute;
        width: 100%;
        left: calc(50% - 32px);
        top: calc(50% - 32px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .youtube-embed, .preloader {
        transition: all .3s;
    }

    &--fullscreen {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
